import { GoogleTag } from './google-tag';
import { GoogleTagParams } from './types';

export const GoogleAds = () => {
  const googleTag = GoogleTag();

  const TrackConversion = (conversionId: string, params?: GoogleTagParams) => {
    googleTag.Track('event', 'conversion', {
      ...params,
      send_to: conversionId,
    });
  };

  return {
    ...googleTag,
    TrackConversion,
  };
};
