interface Params {
  email: string;
  [key: string]: unknown;
}

// https://developers.klaviyo.com/en/v1-2/docs/introduction-to-the-klaviyo-object
export const Klaviyo = () => {
  const TrackEvent = async (event: string, params?: Params) => {
    if (typeof window?.klaviyo !== 'undefined') {
      const isIdentified = await window.klaviyo.isIdentified();

      if (params?.email && !isIdentified) {
        await window.klaviyo.identify({
          $email: params.email,
        });
      }

      window.klaviyo.push(['track', event, params]);
    }
  };

  const Purchase = (params?: Params) => TrackEvent('Purchase', params);

  return {
    TrackEvent,
    Purchase,
  };
};
