import { TikTokEvents, TikTokIdentityOptions, TikTokParams, TikTokSettings } from './types';

export const TikTok = (defaultOptions?: TikTokIdentityOptions) => {
  const getInitScript = (id: string) => `!function (w, d, t) {
    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
    var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
    ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};


      ttq.load('${id}');
      ttq.page();
    }(window, document, 'ttq');`;

  const TrackEvent = (event: TikTokEvents, params?: Partial<TikTokParams>, settings?: TikTokSettings) => {
    const { email, phoneNumber, leadId, ...eventParams } = params || {};

    if (typeof window.ttq !== 'undefined') {
      window.ttq.identify({
        email: email || defaultOptions?.email,
        phone_number: phoneNumber || defaultOptions?.phoneNumber,
        external_id: leadId || defaultOptions?.leadId,
      });

      window.ttq.track(event, eventParams as TikTokParams, settings);
    }
  };

  const Purchase = (orderId: string, params: TikTokParams) =>
    TrackEvent('CompletePayment', params, { event_id: orderId });

  const ViewContent = (params?: Partial<TikTokParams>) => TrackEvent('ViewContent', params);

  const InitiateCheckout = (params?: Partial<TikTokParams>) => TrackEvent('InitiateCheckout', params);

  const AddToCart = (params?: Partial<TikTokParams>) => TrackEvent('AddToCart', params);

  // custom event
  const EmailLeft = (params?: Partial<TikTokParams>) => TrackEvent('EmailLeft', params);

  // custom event
  const PlansVisit = (params?: Partial<TikTokParams>) => TrackEvent('PlansVisit', params);

  return {
    getInitScript,
    TrackEvent,
    Purchase,
    InitiateCheckout,
    ViewContent,
    AddToCart,
    EmailLeft,
    PlansVisit,
  };
};
