import { CustomFields, UTMParams } from '@menesko/models-harmonia';
import cookie from 'js-cookie';

import { parseJSON } from '~/utils/parse-json';

export const useCookies = (): { getUTMParams: () => UTMParams; getCustomFields: () => CustomFields } => {
  const utm = cookie.get('x-utm');

  const getUTMParams = (): UTMParams => {
    if (!utm) {
      return {};
    }

    return parseJSON<UTMParams>(utm);
  };

  return {
    getCustomFields: () => ({
      fbclid: cookie.get('_fbc') || cookie.get('x-fbclid'),
      fbpixel: cookie.get('_fbp'),
      ttclid: cookie.get('ttclid') || cookie.get('x-ttclid'),
      ttpixel: cookie.get('_ttp'),
      ip: cookie.get('x-ip'),
      city: cookie.get('x-city'),
      countryCode: cookie.get('x-country-code'),
      country: cookie.get('x-country'),
      region: cookie.get('x-region'),
      postal: cookie.get('x-postal'),
      userAgent: typeof navigator === 'undefined' ? undefined : navigator.userAgent,
      ...getUTMParams(),
    }),
    getUTMParams,
  };
};
