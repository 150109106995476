import { Lead } from '@menesko/models-harmonia';

export const DEFAULT_LEAD_VALUE: Partial<Lead> = {
  id: undefined,
  email: undefined,
  selectedPlan: undefined,
  isMetric: false,
  quizAnswers: {
    weight: '',
    targetWeight: '',
    age: '',
    gender: 'female',
    skin_changes: '',
    brain_fog: '',
    weight_gain: '',
    hungry_after_meal: '',
    challenging_lose_weight: '',
    increase_urination: '',
    suffer_from_headaches: '',
    moon_face: '',
  },
};

export const FUNNELS = {
  main: 'main',
  cortisol: 'cortisol',
  'cortisol-1': 'cortisol-1',
  'cortisol-2': 'cortisol-2',
  'cortisol-3': 'cortisol-3',
  'cortisol-4': 'cortisol-4',
  'cortisol-5': 'cortisol-5',
};
