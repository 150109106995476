import { Klaviyo, MetaPixel, GoogleAds, VWO, TikTok } from '@shared/trackit';
import { getAnalytics } from 'firebase/analytics';

import { firebaseApp } from '~/firebase-init';
import { useFirebaseAnalytics, FirebaseEventParams } from '~/hooks/useFirebaseAnalytics';

import { useAppLead } from './useAppLead';

interface CheckoutPurchase {
  orderId: string;
  currency: string;
  value: number;
  items: Array<{
    item_id: string;
    item_name: string;
  }>;
  purchaseType: 'initial' | 'upsell';
}

export const useTracking = () => {
  const vwo = VWO();
  const { email, id, shippingDetails, selectedPlan } = useAppLead();
  const tiktok = TikTok({ email, leadId: id, phoneNumber: shippingDetails?.phone });
  const { visit, log, setUserProperties } = useFirebaseAnalytics();

  const visitPlans = (params?: FirebaseEventParams) => {
    visit('plans', params);

    MetaPixel().ViewContent();
    tiktok.PlansVisit();
    tiktok.ViewContent();
  };

  const visitShipping = (params?: FirebaseEventParams) => {
    visit('shipping', params);
  };

  const visitCheckout = (params?: FirebaseEventParams) => {
    visit('checkout', params);

    MetaPixel().InitiateCheckout();
    GoogleAds().TrackConversion('AW-16857452270/jiC-CIH1h6QaEO6doeY-');
    vwo.CheckoutVisit();
  };

  const quizStart = (params?: FirebaseEventParams) => log('quiz_start', params);
  const quizFinish = (params?: FirebaseEventParams) => log('quiz_finish', params);

  const plansSelect = (params: FirebaseEventParams) => {
    log('plans_select', params);

    tiktok.AddToCart({ content_type: 'product', contents: [{ content_id: params.category }] });
  };

  const checkoutSubmit = (params: FirebaseEventParams) => log('checkout_submit', params);

  const shippingSubmit = () => {
    log('shipping_submit', { category: 'submit', label: 'submit' });

    vwo.ShippingSubmit();

    tiktok.InitiateCheckout({ content_type: 'product', contents: [{ content_id: selectedPlan?.key as string }] });
  };

  const quizQuestionAnswer = (params: FirebaseEventParams) => log('quiz_question_answer', params);

  const emailLeft = (params: Partial<FirebaseEventParams> & { leadId: string; email?: string }) => {
    setUserProperties({ id: params.leadId });
    log('email_left', { category: 'email', label: 'left', ...params });

    MetaPixel().Lead({ eventId: params.leadId });
    GoogleAds().TrackConversion('AW-16857452270/6LM0CIT1h6QaEO6doeY-');
    tiktok.EmailLeft({ email: params.email, leadId: params.leadId });
  };

  const checkoutPurchase = (params: Partial<FirebaseEventParams> & CheckoutPurchase) => {
    const { value, currency, orderId, items, purchaseType } = params;

    log('purchase', {
      transaction_id: orderId,
      currency,
      value,
      items,
      category: `purchase_${purchaseType}`,
    });

    if (purchaseType === 'initial') {
      MetaPixel().Purchase({ value, currency, eventId: orderId });
      Klaviyo().Purchase({ email });
      GoogleAds().TrackConversion('AW-16857452270/Shl1CP70h6QaEO6doeY-', {
        value,
        currency,
        transaction_id: orderId,
      });

      vwo.Purchase({ value });
      tiktok.Purchase(orderId, {
        value,
        currency,
        content_type: 'product',
        contents: [
          {
            content_id: items[0].item_id,
            content_name: items[0].item_name,
          },
        ],
      });
    }
  };

  const sessionInit = () => {
    getAnalytics(firebaseApp.app);

    log('session_init');
  };

  return {
    sessionInit,
    visitPlans,
    visitShipping,
    visitCheckout,
    quizStart,
    quizFinish,
    quizQuestionAnswer,
    checkoutSubmit,
    checkoutPurchase,
    plansSelect,
    emailLeft,
    shippingSubmit,
  };
};
