import { VWOParams } from './types';

export const VWO = () => {
  const TrackEvent = (event: string, params?: VWOParams) => {
    if (typeof window.VWO !== 'undefined') {
      window.VWO.event(event, params);
    }
  };

  const CheckoutVisit = (params?: VWOParams) => TrackEvent('checkoutVisit', params);

  const Purchase = (params?: VWOParams) => TrackEvent('purchase', params);

  const PurchaseUpsell = (params?: VWOParams) => TrackEvent('purchaseUpsell', params);

  const ShippingSubmit = (params?: VWOParams) => TrackEvent('shippingSubmit', params);

  return {
    TrackEvent,
    CheckoutVisit,
    Purchase,
    PurchaseUpsell,
    ShippingSubmit,
  };
};
