import { logEvent, getAnalytics, setUserProperties as setUProperties, setUserId } from 'firebase/analytics';

import { firebaseApp } from '~/firebase-init';

import { useAppRouter } from './useAppRouter';
import { useCookies } from './useCookies';

export type FirebaseEvent =
  | 'session_init'
  | 'checkout_submit'
  | 'plans_select'
  | 'email_left'
  | 'quiz_start'
  | 'quiz_finish'
  | 'quiz_question_answer'
  | 'purchase'
  | 'shipping_submit';

export interface FirebaseEventParams {
  category: string;
  label: string;
  [key: string]: unknown;
}

interface UserProperties {
  id: string;
}

export const useFirebaseAnalytics = () => {
  const cookies = useCookies();
  const { funnelName } = useAppRouter();

  const log = (eventName: FirebaseEvent, eventParams?: Partial<FirebaseEventParams>) => {
    logEvent(getAnalytics(firebaseApp.app), eventName as string, {
      ...cookies.getUTMParams(),
      ...eventParams,
      utm_funnel: funnelName,
    });
  };

  const visit = (page: string, params: FirebaseEventParams = { category: 'pageview', label: 'visit' }) =>
    logEvent(getAnalytics(firebaseApp.app), `${page}_visit`, params);

  const setUserProperties = (properties: UserProperties) => {
    const { id, ...restProperties } = properties;

    if (id) {
      setUserId(getAnalytics(firebaseApp.app), id);
    }

    if (Object.values(restProperties).some(Boolean)) {
      const filteredProperties: Record<string, string> = {};

      for (const key in restProperties) {
        if (properties[key as keyof UserProperties]) {
          filteredProperties[key] = properties[key as keyof UserProperties] as string;
        }
      }

      setUProperties(getAnalytics(firebaseApp.app), filteredProperties);
    }
  };

  return {
    log,
    setUserProperties,
    visit,
  };
};
