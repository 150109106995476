import { GoogleTagAction, GoogleTagEvent, GoogleTagParams } from './types';

export const GoogleTag = () => {
  const Track = (action: GoogleTagAction, event: GoogleTagEvent, params?: GoogleTagParams) => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag(action, event, params);
    }
  };

  const SetUserData = (params: GoogleTagParams) => {
    Track('set', 'user_data', params);
  };

  return {
    Track,
    SetUserData,
  };
};
