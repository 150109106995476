import { FirebaseApp } from '@shared/firebase-client';
import axios from 'axios';

import { config } from './config';

export enum FunctionName {
  LEAD = 'lead',
  PRODUCT = 'product',
  PAYMENT = 'payment',
  ORDER = 'order',
  AUTH_SIGN_IN = 'auth/sign-in',
  CLIENT = 'client',
  CLIENT_SUBSCRIPTION = 'client/subscription',
  PAYMENT_UPSELL = 'payment/upsell',
  PAYMENT_METHODS = 'payment/methods',
}

export const firebaseApp = new FirebaseApp(
  {
    apiKey: config.FIREBASE.API_KEY,
    appId: config.FIREBASE.APP_ID,
    projectId: config.FIREBASE.PROJECT_ID,
    name: config.NAME,
  },
  { disableAuthEmulator: !config.IS_DEV, disableFunctionsEmulator: true },
);

export const APIClient = axios.create();

APIClient.interceptors.response.use(
  (req) => req.data,
  (error) => {
    const errorMessage = error.response?.data?.message || error.message || 'Something went wrong';

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ message: errorMessage, ...error?.response?.data });
  },
);

export const getAPIUrl = (fnName: string) =>
  (config.IS_DEV
    ? `http://127.0.0.1:4002/harmonia-staging/us-central1/api/${fnName}`
    : `https://api-${config.FIREBASE.HASH}-uc.a.run.app/${fnName}`);
